<template>
    <div>
        <el-table ref="deviceList" :data="tableData" :highlight-current-row="true" max-height="580" style="width: 100%">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="positionName" label="站点名称" min-width="200"></el-table-column>
            <el-table-column prop="stationName" label="雨棚" min-width="103">
                <template slot-scope="{ row }">
                    <span v-if="row.bikeShedFlag==0">无</span>
                    <span v-else-if="row.bikeShedFlag==1">有</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="stationName" label="位置" min-width="103">
                <template slot-scope="{ row }">
                    <span v-if="row.undergroundFlag==0">地上</span>
                    <span v-else-if="row.bikeShedFlag==1">地下</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="stationName" label="信号" min-width="103">
                <template slot-scope="{ row }">
                    <span v-if="row.phoneSignalFlag==0">无</span>
                    <span v-else-if="row.phoneSignalFlag==1">有</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="pointNum" label="预估安装" min-width="103">
                <template slot-scope="{ row }">
                    <span>{{ row.pointNum?row.pointNum:'-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="approvalPointNum" label="审批回执安装" min-width="120">
                <template slot-scope="{ row }">
                    <span>{{ row.approvalPointNum?row.approvalPointNum:'-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="stationName" label="审核状态" min-width="103">
                <template slot-scope="{ row }">
                    <span v-if="row.checkStatus==0" class="checkpass">已回执</span>
                    <span v-else-if="row.checkStatus==1" class="checkrefuse">审核拒绝</span>
                    <span v-else-if="row.checkStatus==2" class="checkWait">待审核</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" width="100" fixed="right">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" content="详情" placement="top">
                        <img src="../../../assets/icon/option-detail.png" class="a-wh-16" @click="toStationInfo(scope.row)" />
                    </el-tooltip>
                    <el-tooltip class="item a-ml-12" effect="dark" content="审核通过" placement="top">
                        <img src="../../../assets/icon/option-agree.png" class="a-wh-16"  @click="audit(0)" v-if='scope.row.checkStatus == 2'/>
                    </el-tooltip>
                    <el-tooltip class="item a-ml-12" effect="dark" content="审核驳回" placement="top">
                        <img src="../../../assets/icon/option-refuse.png" class="a-wh-16" @click="audit(1)" v-if='scope.row.checkStatus == 2'/>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <AuditDialog @auditFinish="auditFinish" ref="stationAudit"></AuditDialog>
    </div>
</template>

<script>
import AuditDialog from './audit-dialog.vue'
export default {
    data() {
        return {
            tableData: []
        };
    },
    props: {
        surveyAreaId: {
            type: String,
            default: "",
        }
    },
    watch: {
        surveyAreaId: {
            immediate: true,
            handler() {
                this.getSurveyStation();
            }
        }
    },
    methods: {
        getSurveyStation() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSurveyStation,
                method: "get",
                params: {
                    surveyAreaId: this.surveyAreaId
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.tableData = res.result.data;
                }
                else {
                    this.$message.error(res.result.message);
                }
            });
        },
        toStationInfo(datas) {
            this.$router.push({
                path: "/sceneCheck/sceneCheck-site-info",
                query: {
                    surveyAreaId: this.surveyAreaId,//现勘id
                    surveyStationId: datas.id,//现勘站点id 
                }
            });
        },
        audit(type, datas) {
            this.$refs['stationAudit'].dialogVisible = true
            this.$refs['stationAudit'].id = datas.id
            this.$refs['stationAudit'].checkStatus = type
        },
        auditFinish () {
            this.getSurveyStation();
        },
    },
    components: { AuditDialog }
}
</script>

<style lang="scss" scoped>
.checkpass, .checkWait{
    background: #F2F8FF;
    border-radius: 5px;
    padding: 3px 11px;
    font-size: 12px;
    color: #007AFF;
    line-height: 22px;
}
.checkrefuse{
    background: #FFEDED;
    border-radius: 5px;
    padding: 3px 11px;
    font-size: 12px;
    color: #FF3B30;
    line-height: 22px;
}
</style>