<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="sceneDetails">
            <div slot="header" class="a-fs-16 a-fw-b">{{ title?title:'-' }}</div>
            <div class="a-flex-rfsc mb32">
                <div class="a-flex-cfsfs" style="width:110px" @click="changeTab(1)">
                    <span :class="tabAc==1?'tabAc':'tabUnAc'">项目信息</span>
                    <div class="tab-line" :class="tabAc==1?'lineAc':'lineUnAc'"></div>
                </div>
                <div class="a-flex-cfsfs" style="width:110px" @click="changeTab(2)">
                    <span :class="tabAc==2?'tabAc':'tabUnAc'">站点列表</span>
                    <div class="tab-line" :class="tabAc==2?'lineAc':'lineUnAc'"></div>
                </div>
                <div class="a-flex-cfsfs" style="width:110px" @click="changeTab(3)">
                    <span :class="tabAc==3?'tabAc':'tabUnAc'">地图点位</span>
                    <div class="tab-line" :class="tabAc==3?'lineAc':'lineUnAc'"></div>
                </div>
            </div>
            <SceneCheckInfoDetails v-if="tabAc==1" :surveyAreaId="surveyAreaId"></SceneCheckInfoDetails>
            <SceneCheckStationList v-if="tabAc==2" :surveyAreaId="surveyAreaId"></SceneCheckStationList>
            <SceneCheckMap v-if="tabAc==3" :surveyAreaId="surveyAreaId"></SceneCheckMap>
        </el-card>
    </div>
</template>

<script>
import SceneCheckStationList from "./child/sceneCheck-station-list.vue";
import SceneCheckInfoDetails from "./child/sceneCheck-info-details.vue";
import SceneCheckMap from "./child/sceneCheck-map.vue";
export default {
    data() {
        return {
            tabAc: 1,
            surveyAreaId: '',//现勘id
            sceneDatas: '',
            title: '',
        };
    },
    created () {
        this.surveyAreaId = this.$route.query.id
        //this.title = this.$route.query.title
		this.getSurveyAreaDetail();
    },
    computed: {
        
    },
    methods: {
        changeTab(tab) {
            this.tabAc = tab;
        },
		getSurveyAreaDetail () {
		    this.$Axios._get({
		        url: this.$Config.apiUrl.getSurveyAreaDetail,
		        method: "get",
		        params: {
		            surveyAreaId: this.surveyAreaId
		        }
		    }).then(({data}) => {
		        this.title= data.buildName
		    })
		}
    },
    components: { SceneCheckStationList, SceneCheckInfoDetails, SceneCheckMap }
}
</script>

<style lang="scss" scoped>
.sceneDetails{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
}
.tab-line{
    width: 60px;
    height: 3px;
    border-radius: 1px;
    margin-top: 11px
}
.lineAc{
    background: #007AFF;
    
}
.lineUnAc{
    background: #ffffff;
}
.tabAc{
    color: #303133;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.tabUnAc{
    color: #606366;
    font-size: 12px;
    cursor: pointer;
}
.mb32{
    margin-bottom: 32px
}
</style>