<template>
    <div>
        <div class="a-flex-rfsc">
            <span class="a-c-master a-fs-16 a-fw-b">现勘人员</span>
            <img src="../../../assets/icon/user-icon.png" style="width:32px;height:32px;margin: 0 11px 0 32px;" alt="">
            <span class="a-c-master a-fs-16 a-fw-b">{{ surveyAreaInfo&&surveyAreaInfo.userName?surveyAreaInfo.userName:'-' }}</span>
            <img src="../../../assets/icon/phone-icon.png" style="width:32px;height:32px;margin: 0 11px 0 64px;" alt="">
            <span class="a-c-master a-fs-16 a-fw-b">{{ surveyAreaInfo&&surveyAreaInfo.mobile?surveyAreaInfo.mobile:'-' }}</span>
        </div>
        <div class="a-c-master a-fs-16 a-fw-b mt64">基本信息</div>
        <div class="a-flex-rfsfs a-flex-wrap">
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">项目名称</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.buildName?surveyAreaInfo.buildName:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">项目地址</span>
                <span class="content a-c-master">
                    {{ surveyAreaInfo&&surveyAreaInfo.areaProvinceName?surveyAreaInfo.areaProvinceName:'' }}
                    {{ surveyAreaInfo&&surveyAreaInfo.areaCityName?surveyAreaInfo.areaCityName:'' }}
                    {{ surveyAreaInfo&&surveyAreaInfo.areaDistrictName?surveyAreaInfo.areaDistrictName:'' }}
                    {{ surveyAreaInfo&&surveyAreaInfo.buildAddress?surveyAreaInfo.buildAddress:'' }}
                </span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">物业公司</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.propertyCompanyName?surveyAreaInfo.propertyCompanyName:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">物业负责人</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.propertyUser?surveyAreaInfo.propertyUser:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">物业负责人电话</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.propertyMobile?surveyAreaInfo.propertyMobile:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">所属社区</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.communityName?surveyAreaInfo.communityName:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">社区负责人</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.communityUser?surveyAreaInfo.communityUser:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">社区负责人电话</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.communityMobile?surveyAreaInfo.communityMobile:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">项目属性</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.buildAttribute==1">商品房</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">地下非机动车库</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.nonMotorizedGarageFlag==1?'有':'无' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">楼栋数量</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.buildNum?surveyAreaInfo.buildNum:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">住户数量</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.residentNum?surveyAreaInfo.residentNum:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">入住率(%)</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.redidentRate?surveyAreaInfo.redidentRate+'%':'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">电动车数量</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.electricVehicle?surveyAreaInfo.electricVehicle:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">预估投放设备总数</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.expectPowerNum?surveyAreaInfo.expectPowerNum:'-' }}</span>
            </div>
        </div>
        <div class="a-c-master a-fs-16 a-fw-b mt64">项目费用</div>
        <div class="a-flex-rfsfs a-flex-wrap">
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">拟定最低收费价格(元/小时)</span>
                <span class="content a-c-master">{{ surveyAreaInfo.minCharge?util.numFormat(surveyAreaInfo.minCharge):'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">电费承担方</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.electricityPayment==0">我方承担</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.electricityPayment==1">物业承担</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">电费价格(元/度)</span>
                <span class="content a-c-master">{{ surveyAreaInfo.electricityFee?util.numFormat(surveyAreaInfo.electricityFee):'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">物业分成比例(%)</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.propertyShared?surveyAreaInfo.propertyShared+'%':'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">合同年限(年)</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.contractYear?surveyAreaInfo.contractYear:'-' }}</span>
            </div>
        </div>
        <div class="a-c-master a-fs-16 a-fw-b mt64">充电桩现状</div>
        <div class="a-flex-rfsfs a-flex-wrap">
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">现有充电方式</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.chargingType==1">传统插座</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">新装/加装</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.retrofitting==0">新装</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.retrofitting==1">加装</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">是否有车辆</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.nonMotorizedGarageFlag==0">否</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.nonMotorizedGarageFlag==1">是</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">车棚是否满足充电桩安装条件</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.bikeShedPowerFlag==0">否</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.bikeShedPowerFlag==1">是</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">是否同意出资建设车棚</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.buildBikeShedFlag==0">否</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.buildBikeShedFlag==1">是</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
        </div>
        <div class="a-c-master a-fs-16 a-fw-b mt64">调研结果</div>
        <div class="a-flex-rfsfs a-flex-wrap" style="margin-bottom: 100px">
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">是否同意安装我司充电桩</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.buildPowerFlag==0">否</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.buildPowerFlag==1">是</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">同意安装日期</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.buildPowerTime?surveyAreaInfo.buildPowerTime:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">整体安装意愿</span>
                <span class="content a-c-master" v-if="surveyAreaInfo&&surveyAreaInfo.buildIntention==1">迫切</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.buildIntention==2">规划中</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.buildIntention==3">有意向</span>
                <span class="content a-c-master" v-else-if="surveyAreaInfo&&surveyAreaInfo.buildIntention==4">观望中</span>
                <span class="content a-c-master" v-else>-</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">调研备注</span>
                <span class="content a-c-master">{{ surveyAreaInfo&&surveyAreaInfo.buildPowerRemark?surveyAreaInfo.buildPowerRemark:'-' }}</span>
            </div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">小区图片/视频</span>
                <div class="content">
                    <LeBtnPreviewImg :imgList="surveyImgs"></LeBtnPreviewImg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LeBtnPreviewImg from '../../components/poppup/le-btn-preview-img.vue'
import util from '../../../../src/utils/util'
export default {
    data() {
        return {
            surveyAreaInfo: '',
            util: util
        };
    },
    props: {
        surveyAreaId: {
            type: String,
            default: '',
        }
    },
    watch:{
        surveyAreaId:{
            immediate:true,
            handler () {
                this.getSurveyAreaDetail()
            }
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company
        }),
        surveyImgs () {
            let arr = []  
            if(this.surveyAreaInfo.buildImage1){
                arr.push(this.surveyAreaInfo.buildImage1)
            }
            if(this.surveyAreaInfo.buildImage2){
                arr.push(this.surveyAreaInfo.buildImage2)
            }
            if(this.surveyAreaInfo.buildImage3){
                arr.push(this.surveyAreaInfo.buildImage3)
            }
            return arr
        }
    },
    methods: {
        getSurveyAreaDetail () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSurveyAreaDetail,
                method: "get",
                params: {
                    currentCompanyId: this.company.id,
                    surveyAreaId: this.surveyAreaId
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.surveyAreaInfo = res.result.data
                    window.localStorage.setItem('sceneCheckInfo',JSON.stringify(this.surveyAreaInfo))
                }else{
                    this.$message.error(res.result.message)
                }
            })
        }
    },
    components: { LeBtnPreviewImg }
}
</script>

<style lang="scss" scoped>
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 21px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 130px;
    margin-right: 16px;
}
.content{
    width: 267px
}
</style>