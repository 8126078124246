<template>
    <div>
        <div class="mapContent" id="sceneCheckMap"></div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                mapContent: null,
                marker: null,
                mapPoints: [],//地图点位      
            }
        },
        props: {
            surveyAreaId: {
                type: String,
                default: "",
            }
        },
        mounted () {
            this.initMap()
            this.getSurveyStation()
        },
        beforeDestroy () {
            this.marker.off("mouseover", ()=>{});
            this.marker.off("mouseout", ()=>{});
        },
        methods:{
            getSurveyStation() {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getSurveyStation,
                    method: "get",
                    params: {
                        surveyAreaId: this.surveyAreaId
                    }
                }).then(res => {
                    if (res.result.code == 0) {
                        this.mapPoints = res.result.data;
                        this.setMarker()
                    }
                    else {
                        this.$message.error(res.result.message);
                    }
                });
            },
            initMap () {
                //定义map变量，调用 TMap.Map() 构造函数创建地图
                this.mapContent = new TMap.Map(document.getElementById('sceneCheckMap'), {
                    center: new TMap.LatLng(39.908823,116.39747),//设置中心点坐标
                    zoom: 10,
                });
            },
            setMarker () {
                let geometries = []
                let coords = []
                this.mapPoints.map((item,index)=>{
                    geometries.push({ //点标注数据数组
                        "id": "demo",
                        "styleId": "marker",
                        "position": new TMap.LatLng(item.lat,item.lng),
                        "properties": {
                            "content": item.positionName
                        },
                    })
                    coords.push(new TMap.LatLng(item.lat,item.lng))
                })
                if(this.mapPoints.length){
                    this.marker = new TMap.MultiMarker({
                        id: "marker-layer", //图层id
                        map: this.mapContent,
                        styles: { //点标注的相关样式
                            "marker": new TMap.MarkerStyle({
                                "width": 32,
                                "height": 44,
                                "anchor": { 'x': 16, 'y': 44 },
                                "src": require("@/assets/icon/map-marker-icon.png")
                            })
                        },
                        geometries: geometries
                    });
                    // 自适应缩放等级 以包含所有的标记点 https://lbs.qq.com/Vis/JavascriptAPI/APIGuide/map/mapState
                    let latlngBounds = new TMap.LatLngBounds();
                    for(var i = 0,l = coords.length;i < l; i++){
                        latlngBounds.extend(coords[i]);
                    }
                    this.mapContent.fitBounds(latlngBounds,{
                        padding: 100 // 自适应边距
                    });

                    //初始化infoWindow
                    let infoWindow = new TMap.InfoWindow({
                        map: this.mapContent,
                        position: new TMap.LatLng(39.984104, 116.307503),
                        offset: { x: 0, y: -50 } //设置信息窗相对position偏移像素
                    });
                    // marker 事件
                    this.marker.on('mouseover', (evt)=>{
                        infoWindow.open(); //打开信息窗
                        infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                        infoWindow.setContent(evt.geometry.properties.content);//设置信息窗内容
                    })
                    this.marker.on('mouseout', ()=>{
                        setTimeout(()=>{
                            infoWindow.close(); //打开信息窗
                        },100)
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.mapContent{
    width: 100%;
    height: 590px;
}
</style>